@import '../../../../styles/constants';

.createProduct {
    margin-bottom: 30px;
}

.products {

    .product {
        position: relative;
        cursor: pointer;
        display: grid;
        grid-template-columns: 70% 30%;
        justify-content: end;
        min-height: 65px;

        h3 {
            margin-left: 5px;
        }

        .form {
            grid-column: 1/3;
        }

        .info {
            display: flex;
            flex-direction: column;

        }

        .quantityAndCost {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            width: 80px;
   

            .quantity {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: row-reverse;
                padding-bottom: 10px;

                button {
                    height: 20px;
                    min-width: 20px;
                    width: 20px;
                    margin: 0;
                }
    
                span {
                    font-size: $V_LARGE_TEXT;
                }
            }
    
            .cost {
                font-size: $SMALL_HEADER;
                margin-right: 5px;
            }
        }

        .hideQuantityButtons {
            justify-content: flex-end;
        }


    }



}