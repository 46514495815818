@import '../../../../styles/constants';


.orders {

    .order {
        position: relative;
        transition: $TRANSITION;
        cursor: pointer;
        display: grid;
        grid-template-columns: 70% 30%;

        .form {
            grid-column: 1/3;
        }

        .info {
            display: flex;
            flex-direction: column;

        }

        .quantity {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span {
                padding: 10px;
            }
        }

        .cost {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            color: $BLACK;
            border-radius: $BORDER_RADIUS;

        }
    }

    .order:hover {
        border: 1px solid $PRIMARY;
        background-color: rgba($PRIMARY,0.1);
    }



}