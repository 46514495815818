@import '../../styles/constants';

nav {
    background-color: $DARK;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: fixed;
    bottom: 0;
    width: 100%;

    a {
        all: unset;
        cursor: pointer;
        color: $WHITE;
        transition: $TRANSITION;
        padding: 10px !important;

        .navLink {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50px;
            width: 70px;

            svg {
                width: 100%;
                height: 30px
            }
        }
        .active {

            svg {
                rect, path, circle {
                    stroke: $PRIMARY;
                }
            }
        }

    }

}