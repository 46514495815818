@import '../../styles/constants';

.auth {
    background: url("../../../public/images/background.jpg") ;
    background-size: cover;
    background-position: top;
    height: 10%;

    span {
        margin-top: 20px;
        font-size: $LARGE_TEXT;
        color: $WHITE;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80vh;
    }

    // h1 {
    //     padding: 20px;
    // }
}