@import '../../styles/constants';


.tile {
    padding: 10px 20px;
    margin-bottom: 10px;
    border: $BORDER;
    border-radius: 100px;
    box-shadow: $BOX_SHADOW;
    transition:  $TRANSITION;
    background-color: $PURPLE;

    display: flex;
    align-items: center;


    span {
        font-size: $V_LARGE_TEXT;
        margin-left: 10px;
    }

    svg {
        max-height: 20px;
        max-width: 20px;

    }
}
