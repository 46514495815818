@import '../../styles/constants';

button {
    all: unset;
    cursor: pointer;

}

.button {
    height: 30px;
    border: $BORDER;
    border-radius: 100px;
    box-shadow: $BOX_SHADOW;
    transition:  $TRANSITION;
    color: $BLACK;
    margin: 5px;
    text-align: center;
    min-width: 30px;
    text-decoration: none;
    font-size: 12px;
    text-transform: lowercase;
}


.primary {
    background-color: $PRIMARY
}

.secondary {
    background-color: $SECONDARY
}

.wide {
    width: 200px;
}