@import '../../styles/constants';

.container {
    max-width: 1000px;
    margin: auto;
    padding: 20px 15px 30px 15px;

    h1 {
        text-align: left;
        margin-bottom: 30px;
    }
}
