@import url('https://fonts.googleapis.com/css2?family=Leckerli+One&family=Poppins:wght@300;400;500&display=swap');
@import './constants';

* {
  margin: 0;
  padding: 0;
}

.app {
  min-height: calc(var(--vh, 1vh) * 100);
}

body {
  touch-action: none;
  font-family: 'Poppins', sans-serif;
  background-color: $ORANGE;
  margin: 0;
  padding: 0;


  
  h1, h2, h3, h4, h5, h6 {
    text-transform: lowercase;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    color: $BLACK;
    font-weight: 400;
  }

  h1 {
    font-family: 'Leckerli One', sans-serif;
    font-size: $LARGE_HEADER;
    text-align: center;
    color: $DARK;
  }

  h2 {
    font-size: $MEDIUM_HEADER;
  }


  h3 {
    font-size: $SMALL_HEADER;
  }

  p, span {
    font-size: $MEDIUM_TEXT;
    color: $BLACK;
  }

  main {
    min-height: 100vh;
  }
  

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .row-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .row-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  a {
    all: unset;
    text-decoration: underline;
    color: $PRIMARY;
    cursor: pointer;
  }

  .box {
    background-color: rgba($YELLOW, 0.8);
    border-radius: $BORDER_RADIUS;
    padding: $PADDING;
    box-shadow: $BOX_SHADOW;
    border: $BORDER;
    margin-bottom: $PADDING;
  }

  mark {
    background: linear-gradient(to top, $WHITE 65%, transparent 50%);
    padding: 0 10px;
  }

}
