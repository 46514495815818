@import '../../styles/constants';

.home {
    background: url("../../../public/images/background.jpg") ;
    background-size: cover;
    background-position: top;
    width: auto;
    height: 100%;
    
    .details {
        max-width: 425px;
        margin: auto;
        margin-top: 20px;


        .detail {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;

            border-top: 2px solid $WHITE;

            span {
                color: $WHITE;
                font-size: $V_LARGE_TEXT;
            }

            .successful {
                background: linear-gradient(to top, $SUCCESS 65%, transparent 50%);
            }
            .pending {
                background: linear-gradient(to top, $PENDING 65%, transparent 50%);
            }
            .failed {
                background: linear-gradient(to top, $FAILED 65%, transparent 50%);
            }

            span:nth-child(1) {
                margin-left: 10px;
            }
            span:nth-child(2) {
                margin-right: 10px;
            }
        }

        .detail:nth-child(1) {
            border-top: none;
        }


    }

    .orders, .order {
        max-width: 425px;
        margin: auto;
    }
    
    .orderHeader {
        h1:nth-child(1) {
            margin: 0;
        }
        h1:nth-child(2) {
            font-size: $MEDIUM_HEADER;
        }
    }

    .checkout {
        margin-top: 20px;
        text-align: end;
    }

}