// Colours 
// $PRIMARY: #91C4F2;
$PRIMARY: #C6F8FF;
// $SECONDARY: #FFE560;
// $SECONDARY: #FFDBDB;
$SECONDARY: #DDE4FF;
$DARK: #1F1A41;
$BLACK: #24272B;
$WHITE: #ffffff;
$YELLOW: #FFFACD;
$GREY: #5c5c5c;
$PURPLE: #DDE4FF;
$RED: red;
$ORANGE: #E94F58;

$SUCCESS: #91C4F2;
$PENDING: $YELLOW;
$FAILED: $DARK;

// Dimensions
$PADDING: 10px;
$BORDER_RADIUS: 10px;

// Misc
$BOX_SHADOW:  3px 3px 0px $BLACK;
$BORDER: 2px solid $BLACK;


// Animations
$TRANSITION: all 0.2s ease-in-out;

// Fonts
$LARGE_HEADER: 35px;
$MEDIUM_HEADER: 22px;
$SMALL_HEADER: 20px;
$V_LARGE_TEXT: 16px;
$LARGE_TEXT: 12px;
$MEDIUM_TEXT: 10px;