@import '../../styles/constants';


.form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: $PADDING 0;
    
}

.formField {
    display: flex;
    flex-direction: column;

    label {
        color: $WHITE 
    }
    margin: 10px;
}

.twoColumns {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    
}

.form {
    width: 100%;
    max-width: 500px;
    margin: auto;

    .groupTitle {
        margin-bottom: 10px;
        text-align: center;
    }

    .fields {
        display: grid;
        grid-template-columns: auto;
        column-gap: 50px;
        row-gap: 10px;
        margin-bottom: 50px;

        .field {
            display: flex;
            flex-direction: column;

            label {
                margin-bottom: 10px;
                color: $WHITE
            }

            input,
            textarea,
            select {
                padding: 10px;
                border-radius: 10px;
                border: 2px solid #EBEBEB;
                resize: vertical;
                cursor: pointer;
            }
        }

    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;


        button {
            width: 100px;
            margin: 0 20px;
            text-align: center;
        }
    }

}